import axios from "axios";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// @mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Checkbox,
  Typography,
  Radio,
  FormControlLabel,
} from "@mui/material";
import Button from "@mui/material/Button";

import { LoadingButton } from "@mui/lab";
import Nav from "./Nav";
import Footer from "./Footer";
// components

// ----------------------------------------------------------------------
const Portfolio = () => {
  return (
    <>
      <section class="banner">
        <div class="container">
          <div class="col-lg-12">
            <div class="banner-nav">
              <div class="banner-box">
                <a href="/">Home </a>
                <a href="portfolio">Portfolio</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="portfolio">
        <div class="container">
          <header class="section-header">
            <h3 class="section-title">Our Portfolio</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              ullamcorper pharetra ligula nec hendrerit. Ut eu libero nec magna
              placerat fringilla.
            </p>
          </header>

          <div class="row">
            <div class="col-lg-12">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">
                  All
                </li>
                <li data-filter=".web-des">Web Design</li>
                <li data-filter=".web-dev">Web Development</li>
                <li data-filter=".app-des">App Design</li>
                <li data-filter=".app-dev">App Development</li>
              </ul>
            </div>
          </div>

          <div class="row portfolio-container">
            <div class="col-lg-4 col-md-6 portfolio-item web-des">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-1.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-1.jpg"
                    data-lightbox="portfolio"
                    data-title="Lorem ipsum dolor"
                    class="link-preview"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Lorem ipsum dolor</h4>
                  <p>Web Design</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item web-dev">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-2.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-2.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Nulla ullamcorper pharetra"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Nulla ullamcorper pharetra</h4>
                  <p>Web Development</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item app-des">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-3.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-3.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Phasellus eget dictum"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Phasellus eget dictum</h4>
                  <p>App Design</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item app-dev">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-4.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-4.jpg"
                    data-lightbox="portfolio"
                    data-title="Lorem ipsum dolor"
                    class="link-preview"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Donec mattis vestibulum</h4>
                  <p>App Development</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item web-des">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-5.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-5.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Nulla ullamcorper pharetra"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Pellentesque ullamcorper</h4>
                  <p>Web Design</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item web-dev">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-6.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-6.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Phasellus eget dictum"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Sed pretium sapien</h4>
                  <p>Web Development</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Portfolio;
