import React from "react";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Test = () => {
  const customStyles = `
  .owl-theme .owl-dots .owl-dot {
    background: red; // Change this to the desired color
  }
`;

  return (
    <section className="testimonials">
      <style>{customStyles}</style>
      <div className="container">
        <header className="section-header">
          <h3 className="section-title">Testimonials</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            ullamcorper pharetra ligula nec hendrerit. Ut eu libero nec magna
            placerat fringilla.
          </p>
        </header>

        <OwlCarousel
          className="owl-carousel testimonials-carousel"
          loop
          margin={10}
          nav
          dots
          items={1} // Display one item at a time
        >
          <div className="testimonial-item row align-items-center">
            <div className="col-sm-3">
              <div className="testimonial-img">
                <img src="img/testimonial-1.jpg" alt="Testimonial image" />
              </div>
            </div>
            <div className="col-sm-9">
              <div className="testimonial-text">
                <h3>Anna M. Brzezinski</h3>
                <h4>businesswoman</h4>
                <p>
                  Nullam vulputate nunc diam, non congue est vestibulum ut.
                  Curabitur placerat mauris non nisi cursus commodo. Integer
                  dolor augue, maximus interdum pretium a, mattis ut mi.
                </p>
              </div>
            </div>
          </div>
          <div className="testimonial-item row align-items-center">
            <div className="col-sm-3">
              <div className="testimonial-img">
                <img src="img/testimonial-2.jpg" alt="Testimonial image" />
              </div>
            </div>
            <div className="col-sm-9">
              <div className="testimonial-text">
                <h3>Shirley H. Lee</h3>
                <h4>businesswoman</h4>
                <p>
                  Nullam vulputate nunc diam, non congue est vestibulum ut.
                  Curabitur placerat mauris non nisi cursus commodo. Integer
                  dolor augue, maximus interdum pretium a, mattis ut mi.
                </p>
              </div>
            </div>
          </div>
          <div className="testimonial-item row align-items-center">
            <div className="col-sm-3">
              <div className="testimonial-img">
                <img
                  src="assets/img/testimonial-3.jpg"
                  alt="Testimonial image"
                />
              </div>
            </div>
            <div className="col-sm-9">
              <div className="testimonial-text">
                <h3>Kerry E. Thomas</h3>
                <h4>businesswoman</h4>
                <p>
                  Nullam vulputate nunc diam, non congue est vestibulum ut.
                  Curabitur placerat mauris non nisi cursus commodo. Integer
                  dolor augue, maximus interdum pretium a, mattis ut mi.
                </p>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Test;
