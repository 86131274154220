import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Nav from "./Nav";
import Footer from "./Footer";

const Services = () => {
  return (
    <>
      <section class="banner">
        <div class="container">
          <div class="col-lg-12">
            <div class="banner-nav">
              <div class="banner-box">
                <a href="/">Home </a>
                <a href="service">Service</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" class="services">
        <div class="container">
          <header class="section-header">
            <h3>Services</h3>
            <p>
              Integer sollicitudin sed nulla non consequat. Nullam vitae erat
              quis leo accumsan ullamcorper. Suspendisse leo purus, pellentesque
              posuere.
            </p>
          </header>
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-desktop"></i>
                <h4>
                  <a href="">Web Design</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-laptop"></i>
                <h4>
                  <a href="">Web Development</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-tablet-portrait"></i>
                <h4>
                  <a href="">App Design</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-phone-portrait"></i>
                <h4>
                  <a href="">App Development</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-desktop"></i>
                <h4>
                  <a href="">Web Design</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-laptop"></i>
                <h4>
                  <a href="">Web Development</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-tablet-portrait"></i>
                <h4>
                  <a href="">App Design</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i class="ion-ios-phone-portrait"></i>
                <h4>
                  <a href="">App Development</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Services;
