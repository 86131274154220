import React, { useEffect, useState, useRef } from "react";
import Nav from "./Nav";
import Footer from "./Footer";

import Test from "../Test";
import Client from "./Client";
import "./home.css";

const Home = () => {
  return (
    <div>
      <section class="slider">
        <div class="slider-container">
          <div
            id="carousel"
            class="carousel  slide carousel-fade"
            data-ride="carousel"
          >
            <ol class="carousel-indicators"></ol>

            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <div class="carousel-background">
                  <img
                    src="img/2.jpg"
                    alt=""
                    style={{ backgroundSize: "cover" }}
                  />
                </div>
                <div class="carousel-container">
                  <div class="carousel-content">
                    <h2>business strategy development</h2>
                    <p>
                      At Avance, we assist businesses in defining their core
                      purpose and future aspirations. We help businesses define
                      their long-term objectives and create actionable plans to
                      achieve them.
                    </p>
                    <a href="/business" class="btn-get-started">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-background">
                  <img
                    src="img/1.jpg"
                    alt=""
                    style={{ backgroundSize: "cover" }}
                  />
                </div>
                <div class="carousel-container">
                  <div class="carousel-content">
                    <h2>Marketing consultation</h2>
                    <p>
                      Avance provide businesses with expert guidance and
                      strategic advice to enhance their marketing efforts,
                      improve brand visibility, and achieve their sales and
                      growth objectives
                    </p>
                    <a href="/marketing" class="btn-get-started">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-background">
                  <img
                    src="img/4.jpg"
                    alt=""
                    style={{ backgroundSize: "cover" }}
                  />
                </div>
                <div class="carousel-container">
                  <div class="carousel-content">
                    <h2>Financial Advisory</h2>
                    <p>
                      Avance offer professional guidance and expertise to
                      individuals, businesses, and organizations to help them
                      make informed decisions regarding their finances,
                      investments, and wealth management. These services are
                      designed to assist clients in achieving their financial
                      goals, optimizing their financial resources, and securing
                      their financial future through strategic planning and
                      prudent financial management.
                    </p>
                    <a href="https://htmlcodex.com" class="btn-get-started">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="carousel-background">
                  <img
                    src="img/5.jpg"
                    alt=""
                    style={{ backgroundSize: "cover" }}
                  />
                </div>
                <div class="carousel-container">
                  <div class="carousel-content">
                    <h2>HR Consulting</h2>
                    <p>
                      We offer strategic guidance and support to businesses and
                      organizations in managing their human resources
                      effectively. From recruitment and talent acquisition to
                      employee training, development, and retention,
                    </p>
                    <a href="https://htmlcodex.com" class="btn-get-started">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <a
              class="carousel-control-prev"
              href="#carousel"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon ion-md-arrow-back"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>

            <a
              class="carousel-control-next"
              href="#carousel"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon ion-md-arrow-forward"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
      <section class="about">
        <div class="container">
          <header class="section-header">
            <h3>About Us</h3>
            <p>
              We pride ourselves on being the go-to partner for businesses
              seeking expert consultancy services tailored to their unique
              needs. With a proven track record of success and a team of
              seasoned professionals, we are committed to driving our clients'
              growth, innovation, and success.
            </p>
          </header>

          <div class="row align-items-center about-row">
            <div class="col-md-6">
              <img src="img/mis.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-md-6">
              <h2 class="title">
                <a href="#">Welcome to Avance Corporate</a>
              </h2>
              <p>
                At Avance, we offer a comprehensive suite of consultancy
                services encompassing business strategy development, marketing
                consultation, financial advisory, HR consulting, and
                demand-driven short- and long-term technical assistance. Our
                team brings a wealth of experience and expertise across various
                industries, allowing us to provide insightful solutions that
                drive tangible results.
              </p>
              <div class="read-more">
                <a href="#">Read More</a>
              </div>
            </div>
          </div>

          <div class="row about-cols">
            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/well.avif.avif" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-disc"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Our Mission</a>
                </h2>
                <p>
                  At Avance, our mission is to empower businesses to thrive by
                  providing strategic consultancy services tailored to their
                  unique needs. We are dedicated to guiding our clients towards
                  sustainable growth, operational excellence, and competitive
                  advantage through innovative solutions, expert insights, and
                  collaborative partnerships.
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/vision.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-eye"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Our Vision</a>
                </h2>
                <p>
                  Our vision at Avance is to be a trusted leader in consultancy
                  services, recognized for our commitment to delivering
                  exceptional value and driving impactful results for our
                  clients. We aspire to continuously evolve, adapt, and
                  innovate, staying at the forefront of industry trends and
                  emerging technologies, while maintaining the highest standards
                  of integrity, professionalism, and client satisfaction.
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/obj.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-options"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Our Objective</a>
                </h2>
                <p>
                  To deliver tailored solutions: Understand each client's unique
                  challenges and goals to provide customized consultancy
                  services that address their specific needs effectively. Assist
                  clients in achieving sustainable growth by developing and
                  implementing strategic plans, optimizing operations, and
                  exploring new opportunities for expansion and diversification
                  and to build long term relationship
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" class="services">
        <div class="container">
          <header class="section-header">
            <h3>Services</h3>
            <p>
              Integer sollicitudin sed nulla non consequat. Nullam vitae erat
              quis leo accumsan ullamcorper. Suspendisse leo purus, pellentesque
              posuere.
            </p>
          </header>
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i>
                  {" "}
                  <img src="img/obj.jpg" alt="" class="img-fluid" />
                </i>
                <h4>
                  <a href="">HR Consulting</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i>
                  {" "}
                  <img src="img/mark.jpg" alt="" class="img-fluid" />
                </i>
                <h4>
                  <a href="">Marketing Consulting</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i>
                  {" "}
                  <img src="img/fin.jpg" alt="" class="img-fluid" />
                </i>
                <h4>
                  <a href="">Finiacial Services</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="single-service">
                <i>
                  {" "}
                  <img src="img/sta.jpg" alt="" class="img-fluid" />
                </i>
                <h4>
                  <a href="">Strategy Consulting</a>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Praesentium.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="call-to-action">
        <div class="container text-center">
          <header class="section-header">
            <h3>Book an Appointment</h3>
            <p>
              Do you want to schedule a consultation with us or discuss your
              business needs and explore how Avance Consultancy can help them
              achieve their goals, or you want to join our webinar or book a
              demo
            </p>
          </header>
          <a class="cta-btn" href="#">
            Book Now
          </a>
        </div>
      </section>

      {/*}  <section class="skills">
        <div class="container">
          <header class="section-header">
            <h3>Our Skills</h3>
            <p>
              Quisque ac tincidunt ipsum egestas viverra mi, ac vehicula enim
              consectetur quis. In imperdiet varius elit, ut convallis lectus
              luctus quis.
            </p>
          </header>

          <div class="skills-content">
            <div class="progress">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span class="skill">
                  HTML <i class="val">100%</i>
                </span>
              </div>
            </div>

            <div class="progress">
              <div
                class="progress-bar bg-info"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span class="skill">
                  CSS <i class="val">90%</i>
                </span>
              </div>
            </div>

            <div class="progress">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                aria-valuenow="80"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span class="skill">
                  jQuery <i class="val">80%</i>
                </span>
              </div>
            </div>

            <div class="progress">
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span class="skill">
                  PHP <i class="val">70%</i>
                </span>
              </div>
            </div>
          </div>

          <div class="row counters">
            <div class="col-lg-3 col-6 text-center">
              <h2 data-toggle="counter-up">100</h2>
              <p>Our Staffs</p>
            </div>

            <div class="col-lg-3 col-6 text-center">
              <h2 data-toggle="counter-up">200</h2>
              <p>Our Clients</p>
            </div>

            <div class="col-lg-3 col-6 text-center">
              <h2 data-toggle="counter-up">300</h2>
              <p>Completed Projects</p>
            </div>

            <div class="col-lg-3 col-6 text-center">
              <h2 data-toggle="counter-up">400</h2>
              <p>Running Projects</p>
            </div>
          </div>
        </div>
  </section>*/}
      {/*}   <section class="portfolio">
        <div class="container">
          <header class="section-header">
            <h3 class="section-title">Our Portfolio</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              ullamcorper pharetra ligula nec hendrerit. Ut eu libero nec magna
              placerat fringilla.
            </p>
          </header>

          <div class="row">
            <div class="col-lg-12">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">
                  All
                </li>
                <li data-filter=".web-des">Web Design</li>
                <li data-filter=".web-dev">Web Development</li>
                <li data-filter=".app-des">App Design</li>
                <li data-filter=".app-dev">App Development</li>
              </ul>
            </div>
          </div>

          <div class="row portfolio-container">
            <div class="col-lg-4 col-md-6 portfolio-item web-des">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-1.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-1.jpg"
                    data-lightbox="portfolio"
                    data-title="Lorem ipsum dolor"
                    class="link-preview"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Lorem ipsum dolor</h4>
                  <p>Web Design</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item web-dev">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-2.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-2.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Nulla ullamcorper pharetra"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Nulla ullamcorper pharetra</h4>
                  <p>Web Development</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item app-des">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-3.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-3.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Phasellus eget dictum"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Phasellus eget dictum</h4>
                  <p>App Design</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item app-dev">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-4.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-4.jpg"
                    data-lightbox="portfolio"
                    data-title="Lorem ipsum dolor"
                    class="link-preview"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Donec mattis vestibulum</h4>
                  <p>App Development</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item web-des">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-5.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-5.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Nulla ullamcorper pharetra"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Pellentesque ullamcorper</h4>
                  <p>Web Design</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item web-dev">
              <div class="portfolio-wrap">
                <figure>
                  <img
                    src="img/portfolio-6.jpg"
                    class="img-fluid"
                    alt="Portfolio"
                  />
                  <a
                    href="img/portfolio-6.jpg"
                    class="link-preview"
                    data-lightbox="portfolio"
                    data-title="Phasellus eget dictum"
                    title="Preview"
                  >
                    <i class="ion-md-eye"></i>
                  </a>
                  <a href="" class="link-details" title="More Details">
                    <i class="ion-md-open"></i>
                  </a>
                </figure>

                <div class="portfolio-info">
                  <h4>Sed pretium sapien</h4>
                  <p>Web Development</p>
                </div>
              </div>
            </div>
          </div>
        </div>
</section>*/}
      <section class="team">
        <div class="container">
          <header class="section-header">
            <h3 class="section-title">Meet our team</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              ullamcorper pharetra ligula nec hendrerit. Ut eu libero nec magna
              placerat fringilla.
            </p>
          </header>

          <div class="row">
            <div class="col-md-3 col-sm-6">
              <div class="card card-block">
                <a href="#">
                  <img alt="" class="team-img" src="img/team-1.jpg" />
                  <div class="card-title-wrap">
                    <span class="card-title">John P. Haight</span>{" "}
                    <span class="card-text">Web Designer</span>
                    <div class="social-nav">
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-twitter"></i>
                      </a>
                      <a href="https://facebook.com/freewebsitecode/">
                        <i class="ion-logo-facebook"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-linkedin"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-instagram"></i>
                      </a>
                      <a href="https://www.youtube.com/channel/UC9HlQRmKgG3jeVD_fBxj1Pw/videos">
                        <i class="ion-logo-youtube"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-md-3 col-sm-6">
              <div class="card card-block">
                <a href="#">
                  <img alt="" class="team-img" src="img/team-2.jpg" />
                  <div class="card-title-wrap">
                    <span class="card-title">David R. Bernard</span>{" "}
                    <span class="card-text">Web Developer</span>
                    <div class="social-nav">
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-twitter"></i>
                      </a>
                      <a href="https://facebook.com/freewebsitecode/">
                        <i class="ion-logo-facebook"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-linkedin"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-instagram"></i>
                      </a>
                      <a href="https://www.youtube.com/channel/UC9HlQRmKgG3jeVD_fBxj1Pw/videos">
                        <i class="ion-logo-youtube"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-md-3 col-sm-6">
              <div class="card card-block">
                <a href="#">
                  <img alt="" class="team-img" src="img/team-3.jpg" />
                  <div class="card-title-wrap">
                    <span class="card-title">Dana A. Thomas</span>{" "}
                    <span class="card-text">App Designer</span>
                    <div class="social-nav">
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-twitter"></i>
                      </a>
                      <a href="https://facebook.com/freewebsitecode/">
                        <i class="ion-logo-facebook"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-linkedin"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-instagram"></i>
                      </a>
                      <a href="https://www.youtube.com/channel/UC9HlQRmKgG3jeVD_fBxj1Pw/videos">
                        <i class="ion-logo-youtube"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-md-3 col-sm-6">
              <div class="card card-block">
                <a href="#">
                  <img alt="" class="team-img" src="img/team-4.jpg" />
                  <div class="card-title-wrap">
                    <span class="card-title">Ava M. Proctor</span>{" "}
                    <span class="card-text">App Developer</span>
                    <div class="social-nav">
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-twitter"></i>
                      </a>
                      <a href="https://facebook.com/freewebsitecode/">
                        <i class="ion-logo-facebook"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-linkedin"></i>
                      </a>
                      <a href="https://freewebsitecode.com/">
                        <i class="ion-logo-instagram"></i>
                      </a>
                      <a href="https://www.youtube.com/channel/UC9HlQRmKgG3jeVD_fBxj1Pw/videos">
                        <i class="ion-logo-youtube"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Test />
      <Client />
      <section class="contact">
        <div class="container">
          <div class="section-header">
            <h3 class="section-title">Contact Us</h3>
            <p>
              i204, i202 Ogba Multipurpose Hall adjacent Sunday market, along
              wempco, Ogba, Ikeja lagos
            </p>
          </div>

          <div class="row contact-info">
            <div class="col-md-4">
              <div class="contact-address">
                <i class="ion-md-pin"></i>
                <h3>Address</h3>
                <address>Ogba, Ikeja, Lagos</address>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-phone">
                <i class="ion-md-call"></i>
                <h3>Phone Number</h3>
                <p>
                  <a href="tel:+123-456-789">+234-807-598-1981</a>
                </p>
              </div>
            </div>

            <div class="col-md-4">
              <div class="contact-email">
                <i class="ion-md-mail"></i>
                <h3>Email</h3>
                <p>
                  <a href="mailto:info@example.com">info@avance.africagit</a>
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26361250.667320687!2d-113.75533773453304!3d36.24128894212527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sbd!4v1574923227698!5m2!1sen!2sbd"
                  frameborder="0"
                  style={{ border: "0" }}
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form">
                <form class="contactForm">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Name"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Subject"
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      rows="5"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div>
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
