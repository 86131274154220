import React from "react";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Client = () => {
  return (
    <section className="clients">
      <div className="container">
        <header className="section-header">
          <h3 className="section-title">Our Clients</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            ullamcorper pharetra ligula nec hendrerit. Ut eu libero nec magna
            placerat fringilla.
          </p>
        </header>

        <OwlCarousel
          className="owl-carousel clients-carousel"
          loop
          margin={10}
          nav
          dots
          items={4} // Display 4 items at a time (adjust as needed)
        >
          {/* Client logos */}
          <div className="item">
            <img src="img/client-1.jpg" alt="Client Logo" />
          </div>
          <div className="item">
            <img src="img/client-2.jpg" alt="Client Logo" />
          </div>
          <div className="item">
            <img src="img/client-3.jpg" alt="Client Logo" />
          </div>
          <div className="item">
            <img src="img/client-4.jpg" alt="Client Logo" />
          </div>
          <div className="item">
            <img src="img/client-5.jpg" alt="Client Logo" />
          </div>
          <div className="item">
            <img src="img/client-6.jpg" alt="Client Logo" />
          </div>
          <div className="item">
            <img src="img/client-7.jpg" alt="Client Logo" />
          </div>
          <div className="item">
            <img src="img/client-8.jpg" alt="Client Logo" />
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Client;
