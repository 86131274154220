import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6 footer-info">
                <h3>Avance</h3>
                <p>
                  We pride ourselves on being the go-to partner for businesses
                  seeking expert consultancy services tailored to their unique
                  needs. With a proven track record of success and a team of
                  seasoned professionals, we are committed to driving our
                  clients' growth, innovation, and success.
                </p>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>About Us</h4>
                <ul>
                  <li>
                    <i class="ion-md-arrow-dropright"></i> <a href="#">Home</a>
                  </li>
                  <li>
                    <i class="ion-md-arrow-dropright"></i>{" "}
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <i class="ion-md-arrow-dropright"></i>{" "}
                    <a href="#">Our services</a>
                  </li>
                  <li>
                    <i class="ion-md-arrow-dropright"></i>{" "}
                    <a href="#">Terms & condition</a>
                  </li>
                  <li>
                    <i class="ion-md-arrow-dropright"></i>{" "}
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-contact">
                <h4>Contact Us</h4>
                <p>
                  Ogba, Ikeja, Lagos
                  <br />
                  Lagos State
                  <br />
                  Nigeria <br />
                  <strong>Phone:</strong> +123-456-7890
                  <br />
                  <strong>Email:</strong> info@avance.africa
                  <br />
                </p>

                <div class="social-links">
                  <a href="https://freewebsitecode.com/">
                    <i class="ion-logo-twitter"></i>
                  </a>
                  <a href="https://facebook.com/freewebsitecode/">
                    <i class="ion-logo-facebook"></i>
                  </a>
                  <a href="https://freewebsitecode.com/">
                    <i class="ion-logo-linkedin"></i>
                  </a>
                  <a href="https://freewebsitecode.com/">
                    <i class="ion-logo-instagram"></i>
                  </a>
                  <a href="https://www.youtube.com/channel/UC9HlQRmKgG3jeVD_fBxj1Pw/videos">
                    <i class="ion-logo-youtube"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 footer-newsletter">
                <h4>Subscription</h4>
                <p>DO you want to subsccribe to our news letter</p>
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
            &copy; Copyright{" "}
            <a href="https://freewebsitecode.com/">Avance Cooporate</a>. All
            Rights Reserved
            <br />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
