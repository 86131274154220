import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import Footer from "./Footer";

const About = () => {
  return (
    <div>
      <section class="banner">
        <div class="container">
          <div class="col-lg-12">
            <div class="banner-nav">
              <div class="banner-box">
                <a href="/">Home </a>
                <a href="about">About</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="about">
        <div class="container">
          <header class="section-header">
            <h3>About Us</h3>
            <p>
              Avance.Africa is a Consulting Company addressing the challenges
              faced by different businesss We combine an extensive field
              presence with a strong development expertise and provide solutions
              to all challenges company faces
            </p>
          </header>

          <div class="row align-items-center about-row">
            <div class="col-md-6">
              <img src="img/about.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-md-6">
              <h2 class="title">
                <a href="#">Welcome to Avance</a>
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                condimentum rutrum ligula. Integer ac porttitor mi. In finibus
                vehicula aliquet. Vestibulum et velit placerat, pretium lorem
                id, porttitor libero. Suspendisse scelerisque nec arcu a
                malesuada. Nulla tempus dictum tristique.
              </p>
              <div class="read-more">
                <a href="#">Read More</a>
              </div>
            </div>
          </div>

          <div class="row about-cols">
            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-mission.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-disc"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Monitoring, Evaluation & Learning</a>
                </h2>
                <p>
                  We support international organisations to monitor results in
                  real time, build on lessons learned and evaluate the impact of
                  large aid & development programmes
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-vision.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-eye"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Finiacial Inclusion</a>
                </h2>
                <p>
                  We help private companies and development institutions broaden
                  access to financial services among all categories of targeted
                  populations
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-objective.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-options"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Telecom and Digital</a>
                </h2>
                <p>
                  We work with mobile network operators, private companies and
                  development institutions willing to develop and scale digital
                  solutions in emerging markets, including in remote or
                  underserved areas
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row about-cols">
            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-mission.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-disc"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Renewable Energy</a>
                </h2>
                <p>
                  We support developers, investors, development agencies and
                  governments willing to improve access to energy and support
                  the transition towards clean energy
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-vision.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-eye"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Economic Growth & Labour Markets</a>
                </h2>
                <p>
                  We support the emergence of a sustainable private sector in
                  emerging markets and in fragile countries through the
                  identification of job and business creation opportunities, the
                  analysis of obstacles to growth and the design of support
                  programmes
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-objective.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-options"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Public Service Delivery</a>
                </h2>
                <p>
                  We support the assessment of population needs, map out
                  existing structure, measure quality and availability of
                  services and advise international donors in future programming
                  for a better health, education and other basic services
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row about-cols">
            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-mission.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-disc"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Our Mission</a>
                </h2>
                <p>
                  Aenean volutpat, dolor eu finibus rhoncus, elit felis vehicula
                  nunc, ut pulvinar ex diam nec lacus. Phasellus sit amet
                  rhoncus turpis. Aenean tincidunt auctor purus, ac sodales
                  sapien sagittis
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-vision.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-eye"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Our Vision</a>
                </h2>
                <p>
                  Aenean volutpat, dolor eu finibus rhoncus, elit felis vehicula
                  nunc, ut pulvinar ex diam nec lacus. Phasellus sit amet
                  rhoncus turpis. Aenean tincidunt auctor purus, ac sodales
                  sapien sagittis
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="about-col">
                <div class="img">
                  <img src="img/about-objective.jpg" alt="" class="img-fluid" />
                  <div class="icon">
                    <i class="ion-ios-options"></i>
                  </div>
                </div>
                <h2 class="title">
                  <a href="#">Our Objective</a>
                </h2>
                <p>
                  Aenean volutpat, dolor eu finibus rhoncus, elit felis vehicula
                  nunc, ut pulvinar ex diam nec lacus. Phasellus sit amet
                  rhoncus turpis. Aenean tincidunt auctor purus, ac sodales
                  sapien sagittis
                </p>
                <div class="read-more">
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
